import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./components/page-loader";
import AuthenticationGuard from "./components/authentication-guard";
import Main from "./components/Main";
import Nav from "./Nav";
import Symbols from "./components/Symbols";
import SymbolSearch from "./components/SymbolSearch";
import SymbolDetails from "./components/Details";
import CreateSymbol from "./SymbolComponents/CreateSymbol";
import Dashboard from "./components/Dashboard";
import Controlpanel from "./components/Controlpanel";
import Collections from "./components/Collections";
import PricePredictionPage from "./components/PricePredictionPage";

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <Nav />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/dashboard/controlpanel" element={<AuthenticationGuard component={Controlpanel}/>} />
        <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard}/>} />
        <Route path="/symbols" element={<AuthenticationGuard component={Symbols}/>} />
        <Route path="/details/:symbol" element={<AuthenticationGuard component={SymbolDetails}/>} />
        <Route path="/details/" element={<AuthenticationGuard component={SymbolSearch}/>} />
        <Route path="/create" element={<AuthenticationGuard component={CreateSymbol}/>} />
        <Route path="/collections" element={<AuthenticationGuard component={Collections}/>} />
        <Route path="/predictor/:symbol" element={<AuthenticationGuard component={PricePredictionPage}/>} />
      </Routes>
    </div>
  );
}

export default App;
