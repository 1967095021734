import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './component.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function SymbolDetails() {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const gridRef = useRef();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [syms, setSyms] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedOption, setSelectedOption] = useState('option2');
  const [numberOfDays, setNumberOfDays] = useState('');
  const [symbolInput, setSymbolInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const params = useParams();
  const symbol = params.symbol || ""; // Extract symbol from URL parameters

  useEffect(() => {
    const fetchSymbols = async () => {
      const symbolsUrl = `${process.env.REACT_APP_API_HOST}/api/symbols`;

      const symbolsResponse = await fetch(symbolsUrl);

      if (symbolsResponse.ok) {
        const symbolsData = await symbolsResponse.json();
        setSyms(symbolsData);
      } else {
        console.error("Failed to fetch symbols.");
      }
    };

    fetchSymbols();
  }, []);

  const popupParent = useMemo(() => document.body, []);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const dateNow = new Date();
    let startDate;

    if (selectedOption === 'option1') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 2);
    } else if (selectedOption === 'option2') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 6);
    } else if (selectedOption === 'option3') {
      startDate = new Date(dateNow);
      startDate.setMonth(startDate.getMonth() - 12);
    } else if (selectedOption === 'option4') {
      if (numberOfDays !== '') {
        const parsedDays = parseInt(numberOfDays, 10);
        if (!isNaN(parsedDays) && parsedDays > 0) {
          startDate = new Date();
          startDate.setDate(startDate.getDate() - parsedDays);
        }
      }
    }

    if (startDate) {
      const filteredData = rowData
        .filter(entry => {
          const entryDate = new Date(entry.market_date);
          return entryDate >= startDate;
        })
        .sort((a, b) => new Date(a.market_date) - new Date(b.market_date)); // Sort by date ascending

      setChartData(filteredData);
    }
  }, [selectedOption, numberOfDays, rowData]); // Depend on rowData

  const createColumnDefs = (width) => {
    return width > 450
      ? [
          { headerName: 'Symbol:', field: 'symbol', filter: true, flex: 1, maxWidth: 135,
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          { headerName: 'Company Name:', field: 'company_name', filter: true, flex: 1, maxWidth: 250,
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          { headerName: 'Market Date:', field: 'market_date', filter: true, flex: 1, maxWidth: 150,
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          { headerName: 'Closing Price:', field: 'closing_price', flex: 1, maxWidth: 150,
            cellRenderer: (params) => {
              const closingPrice = params.data.closing_price;
              return <div>${closingPrice}</div>;
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          { headerName: 'Closing Volume:', field: 'closing_volume', flex: 1, maxWidth: 150,
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          { headerName: 'Daily Trend:', field: 'daily_trend', filter: true, flex: 1, maxWidth: 150,
            cellStyle: (params) => {
              if (params.value > 0) {
                return { color: '#0ca81e' };
              } else if (params.value === 0) {
                return { color: '#000' };
              } else if (params.value < 0) {
                return { color: 'red' };
              }
            },
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
          },
          { headerName: 'Smoothed Trend:', field: 'smoothed_trend', filter: true, flex: 1, maxWidth: 175,
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: (params) => {
              if (params.value > 0) {
                return { color: '#0ca81e' };
              } else if (params.value === 0) {
                return { color: '#000' };
              } else if (params.value < 0) {
                return { color: 'red' };
              }
            },
          },
        ]
      : [
          { headerName: 'Market Date:', field: 'market_date', filter: true, flex: 1, maxWidth: 150,
            headerClass: 'small-header',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: {
              fontSize: '10px',
            },
            valueFormatter: (params) => {
              const date = new Date(params.value);
              return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(-2)}`;
            }
          },
          { headerName: 'Closing Price:', field: 'closing_price', flex: 1, maxWidth: 150,
            headerClass: 'small-header',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: {
              fontSize: '10px',
            },
          },
          { headerName: 'Daily Trend:', field: 'daily_trend', filter: true, flex: 1, maxWidth: 150,
            headerClass: 'small-header',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: (params) => ({
              fontSize: '10px',
              color: params.value > 0 ? '#0ca81e' : params.value < 0 ? 'red' : '#000',
            }),
          },
          { headerName: 'Smoothed Trend:', field: 'smoothed_trend', filter: true, flex: 1, maxWidth: 150,
            headerClass: 'small-header',
            cellClass: (params) => isFriday(params.data.market_date) ? 'highlight-friday' : '',
            cellStyle: (params) => ({
              fontSize: '10px',
              color: params.value > 0 ? '#0ca81e' : params.value < 0 ? 'red' : '#000',
            }),
          },
        ];
  };

  const columnDefs = createColumnDefs(width);

  const [PriceChart, setPriceChart] = useState({
    autoSize: true,
    data: [],
    legend: {
      enabled: true,
      position: 'bottom',
    },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'closing_price',
        title: 'Closing Price',
        stroke: '#800080',
        marker: {
          enabled: true,
          size: 4,
          fill: '#800080',
        },
      },
    ],
    axes: [
      {
        type: 'category',
        position: 'bottom',
      },
      {
        type: 'number',
        position: 'left',
        crossLines: [
          {
            value: 0,
            strokeWidth: 2,
            stroke: '#00008B',
            lineDash: [6, 3],
          },
        ],
      },
    ],
  });

  const [TrendChart, setTrendChart] = useState({
    autoSize: true,
    data: [],
    legend: {
      enabled: true,
      position: 'bottom',
    },
    series: [
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'daily_trend',
        title: 'Daily Trend',
        stroke: '#FF5349',
        marker: {
          enabled: true,
          size: 4,
          fill: '#FF5349',
        },
      },
      {
        type: 'line',
        xKey: 'market_date',
        yKey: 'smoothed_trend',
        title: 'Smoothed Trend',
        stroke: '#FFB427',
        marker: {
          enabled: true,
          size: 4,
          fill: '#FFB427',
        },
      },
    ],
    axes: [
      {
        type: 'category',
        position: 'bottom',
      },
      {
        type: 'number',
        position: 'left',
        crossLines: [
          {
            value: 0,
            strokeWidth: 2,
            stroke: '#00008B',
            lineDash: [6, 3],
          },
        ],
      },
    ],
  });

  useEffect(() => {
    setPriceChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  useEffect(() => {
    setTrendChart((prevOptions) => ({
      ...prevOptions,
      data: chartData,
    }));
  }, [chartData]);

  function isFriday(dateString) {
    const marketDate = new Date(dateString);
    return marketDate.getDay() === 4;
  }

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }), []);

  const cellClickedListener = useCallback((event) => {
    // Handle cell click event here if needed
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!symbol) return;

      try {
        const token = await getAccessTokenSilently({
          audience: "https://uptrenddowntrend.com/api",
          scope: "openid profile email",
        });
        const result = await fetch(`${process.env.REACT_APP_API_HOST}/api/symbol_data/${symbol}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!result.ok) {
          throw new Error(`Network response was not ok. Status: ${result.status}`);
        }

        const data = await result.json();
        setRowData(data);
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error.message);
      }
    };

    fetchData();
  }, [symbol, getAccessTokenSilently]);

  const handleSymbolInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    setSymbolInput(input);
    if (input.length > 0) {
      const filteredSuggestions = syms.filter(s => s.symbol.startsWith(input)).slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (symbol) => {
    setSymbolInput(symbol);
    setSuggestions([]);
    navigate(`/details/${symbol}`);
  };

  const search = symbol.toUpperCase();

  const symbolHolder = syms.map(s => s.symbol);

  const [quickFilterText, setQuickFilterText] = useState('');

  const buttonListener = useCallback(() => {
    gridRef.current.api.deselectAll();
  }, []);

  let trendHigh = 0;
  let trendLow = 0;
  let smoothedHigh = 0;
  let smoothedLow = 0;

  if (rowData.length > 0) {
    trendHigh = Math.max(...rowData.map(d => d.daily_trend));
    trendLow = Math.min(...rowData.map(d => d.daily_trend));
    smoothedHigh = Math.max(...rowData.map(d => d.smoothed_trend));
    smoothedLow = Math.min(...rowData.map(d => d.smoothed_trend));
  } else {
    return null;
  }

  let trendRP = 0;
  let smoothedRP = 0;

  if (rowData.length > 0) {
    const dailyTrendData = rowData[0].daily_trend;
    if (dailyTrendData > 0) {
      trendRP = ((dailyTrendData / trendHigh) * 100).toFixed(2);
    } else if (dailyTrendData < 0) {
      trendRP = ((dailyTrendData / trendLow) * 100).toFixed(2);
    }

    const dailySmoothedData = rowData[0].smoothed_trend;
    if (dailySmoothedData > 0) {
      smoothedRP = ((dailySmoothedData / smoothedHigh) * 100).toFixed(2);
    } else if (dailySmoothedData < 0) {
      smoothedRP = ((dailySmoothedData / smoothedLow) * 100).toFixed(2);
    }
  }

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (symbolHolder.includes(search)) {
    return (
      <>
        <div className="symbol-details-main" style={{ display: 'flex', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '45%' }}>
            {syms.map((symbolItem) => {
              if (symbolItem.symbol === search) {
                return (
                  <div key={symbolItem.symbol} className="symbol_items_container">
                    <ul className="symbol_items">
                      <li className="symbol_title">
                        {symbolItem.symbol}
                      </li>
                      <li className="symbol_cp_name">
                        {symbolItem.company_name}
                      </li>
                      <li className="symbol_exchange">
                        {symbolItem.exchange}
                      </li>
                      <li className="symbol_link">
                        {symbolItem.website ? (
                          <a href={symbolItem.website} target="_blank" rel="noopener noreferrer">{symbolItem.website}</a>
                        ) : "No Website Available"}
                      </li>
                    </ul>
                  </div>
                );
              }
              return null;
            })}
            {width > 450 ?
              <div style={{ display: 'flex', flexDirection: 'space-around', color: 'white', border: '1px solid white',
                minWidth: '15%', padding: '1rem', borderRadius: '5px', justifyContent: 'center', flexDirection: 'column', height: '142px' }}>
                <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>Uptrend_Downtrend Predictors:</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ marginRight: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Current: <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].daily_trend}</span></h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{trendRP}%</span></h6>
                  </div>
                  <div style={{ marginLeft: '.5rem' }}>
                    <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Current: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].smoothed_trend}</span></h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                    <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{smoothedRP}%</span></h6>
                  </div>
                </div>
              </div> : null}
          </div>
          {width < 450 ?
            <div style={{ display: 'flex', flexDirection: 'space-around', color: 'white', border: '1px solid white',
              minWidth: '30%', padding: '1rem', borderRadius: '5px', marginBottom: '1rem', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ textTransform: 'uppercase', color: '#00b1ff' }}>Uptrend_Downtrend Predictors:</div>
              <div style={{ padding: '.5rem' }}>
                <h3 style={{ textDecoration: 'underline' }}>Daily Trend</h3>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Current:  <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].daily_trend}</span></h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {trendHigh}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {trendLow}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].daily_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{trendRP}%</span></h6>
              </div>
              <div style={{ padding: '.5rem' }}>
                <h3 style={{ textDecoration: 'underline' }}>Smoothed Trend</h3>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Current: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{rowData[0].smoothed_trend}</span></h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>High: {smoothedHigh}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Low: {smoothedLow}</h6>
                <h6 style={{ display: 'flex', fontSize: '10px' }}>Relative Percentage: <span style={{ marginLeft: '2px', color: rowData[0].smoothed_trend > 0 ? '#0ca81e' : '#ff0e0e' }}>{smoothedRP}%</span></h6>
              </div>
            </div> : null
          }

          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }}>
            <div style={{ position: 'relative', marginRight: '1rem' }}>
              <input
                type="text"
                placeholder="Jump to Symbol"
                value={symbolInput}
                onChange={handleSymbolInputChange}
                style={{
                  color: 'black',
                  padding: '0.5rem',
                  borderRadius: '5px',
                  width: '150px',
                  marginBottom: '0.5rem',
                }}
              />
              {suggestions.length > 0 && (
                <ul
                  style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '150px',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  {suggestions.map((suggestion) => (
                    <li
                      key={suggestion.symbol}
                      onClick={() => handleSuggestionClick(suggestion.symbol)}
                      style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                      }}
                    >
                      {suggestion.symbol}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <button style={{ color: 'white', border: '1px solid white', padding: '.3rem', borderRadius: '5px', backgroundColor: "#134f42" }} onClick={onBtnExport}>Export as CSV</button>
            <button style={{ color: 'white', border: '1px solid white', padding: '.3rem', borderRadius: '5px', backgroundColor: "#134f42", marginLeft: '0.5rem' }} onClick={() => navigate(`/predictor/${symbol}`)}>Predictor</button>
          </div>
          {width > 450 ?
            <div className="ag-theme-alpine" style={{ width: '80%', maxWidth: '1110px', height: 'calc(100vh - 370px)', overflowX: 'auto', marginBottom: '.5rem' }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                cellSelection={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                onCellClicked={cellClickedListener}
                quickFilterText={quickFilterText}
                suppressExcelExport={true}
                popupParent={popupParent}
              />
            </div> :
            <div className="ag-theme-alpine" style={{ width: '80%', height: 'calc(100vh - 470px)', overflowX: 'auto', marginBottom: '.5rem', fontSize: '14px' }}>
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection="multiple"
                rowMultiSelectWithClick={true}
                cellSelection={true}
                enableCellTextSelection={true}
                ensureDomOrder={true}
                onCellClicked={cellClickedListener}
                quickFilterText={quickFilterText}
              />
            </div>
          }

          <div style={{ color: 'white' }}>
            <div className="details-container">
              <div className="ag-theme-alpine details-grid">
                {/* Add any additional grid elements or configurations here */}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-start", fontSize: '15px', marginLeft: '.5rem' }}>
                <div>
                  <input type="radio" id="option1" name="options" value="option1" style={{ marginRight: '.2rem' }} onChange={() => setSelectedOption('option1')} checked={selectedOption === 'option1'} />
                  <label htmlFor="option1" style={{ marginRight: '.5rem' }}>Previous Month</label>
                </div>
                <div>
                  <input type="radio" id="option2" name="options" value="option2" style={{ marginRight: '.2rem' }} onChange={() => setSelectedOption('option2')} checked={selectedOption === 'option2'} />
                  <label htmlFor="option2" style={{ marginRight: '.5rem' }}>Previous 6 Months</label>
                </div>
                <div>
                  <input type="radio" id="option3" name="options" value="option3" style={{ marginRight: '.2rem' }} onChange={() => setSelectedOption('option3')} checked={selectedOption === 'option3'} />
                  <label htmlFor="option3" style={{ marginRight: '.5rem' }}>Previous Year</label>
                </div>
                <div>
                  <input type="radio"
                    id="option4"
                    name="options"
                    value="option4"
                    style={{ marginRight: '.2rem' }}
                    onChange={() => setSelectedOption('option4')} checked={selectedOption === 'option4'}
                  />
                  <input
                    type="number"
                    id="option4"
                    placeholder="Enter Days"
                    name="options"
                    value={numberOfDays}
                    style={{ marginRight: '.2rem', color: "black", borderRadius: '2px' }}
                    onChange={(e) => setNumberOfDays(e.target.value)}
                    onClick={() => setSelectedOption('option4')}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="details-chart" style={{ display: "flex", marginBottom: '1rem' }}>
                  <div style={{ margin: '.5rem' }}>
                    <AgChartsReact options={PriceChart} />
                  </div>
                  <div style={{ margin: '.5rem' }}>
                    <AgChartsReact options={TrendChart} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="error-container">
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'Center', paddingTop: '2rem', color: 'white', fontSize: '2rem' }}>
            SYMBOL DOES NOT EXIST
          </div>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'Center', paddingTop: '2rem', color: 'white', flexDirection: 'column', alignItems: 'center' }}>
            <h6>
              Would you like to add this symbol?
            </h6>
            <button className="no-symbol-btn" style={{ border: '1px solid white', maxWidth: '9rem', padding: '.7rem', borderRadius: '5px', marginTop: '.5rem' }}>
              <Link to="/dashboard/controlpanel">ADD SYMBOL</Link>
            </button>
          </div>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'Center', paddingTop: '2rem', color: 'white', flexDirection: 'column', alignItems: 'center' }}>
            <h6>
              Search for a different symbol
            </h6>
            <button className="no-symbol-btn" style={{ border: '1px solid white', maxWidth: '12rem', padding: '.8rem', borderRadius: '5px', marginTop: '.5rem' }}>
              <Link to="/details">SEARCH SYMBOL</Link>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SymbolDetails;
